
































































































import { Component, Prop } from 'vue-property-decorator'
import dmaCodes from '@/data/dma_options'
import moment from 'moment'
import ViewModel from '@/models/ViewModel'
import Inventory from '@/models/Inventory'
import User from '@/models/User'
import Creative from '@/models/Creative'
import CreativeName from '@/components/CreativePicker/CreativeName.vue'

@Component({
  components: {
    CreativeName,
  },
})
export default class BookingHistoryProperty extends ViewModel {
  @Prop({ default: '' })
  public key_name!: string;

  @Prop({ default: null })
  public old_value!: any;

  @Prop({ default: null })
  public new_value!: any;

  public value: any;

  public formatProperty() {
    if (!this.new_value) {
      return 'N/A'
    }

    let ret = this.new_value

    switch (this.key_name) {
      case 'impressions':
        ret = this.$options.filters!.abbreviate(ret)
        break
      case 'account_executive_id':
        ret = User.find(ret)
        return ret.name
      case 'start_at':
      case 'end_at':
        ret = moment(ret, 'YYYY-MM-DD').format('MMMM, D YYYY')
        break
    }

    return this.$options.filters!.capitalize(ret)
  }

  public parseTargetingChanges() {
    this.value = []
    for (const key in this.new_value) {
      const prop: any = []
      for (const k in this.new_value[key]) {
        const has_old = this.old_value != null
          && this.old_value.hasOwnProperty(key)
          && this.old_value[key] != null

        if (!has_old || !this.old_value[key].includes(this.new_value[key][k])) {
          prop.push({
            action: 'add',
            value: this.new_value[key][k],
          })
        }
      }

      if (this.old_value != null && this.old_value.hasOwnProperty(key)) {
        for (const k in this.old_value[key]) {
          const has_new = this.new_value != null
            && this.new_value.hasOwnProperty(key)
            && this.new_value[key] != null

          if (
            !has_new
            || !this.new_value[key].includes(this.old_value[key][k])
          ) {
            prop.push({
              action: 'remove',
              value: this.old_value[key][k],
            })
          }
        }
      }
      this.value[key] = prop
    }
  }

  public parseCreativeChanges() {
    this.value = []

    for (const k in this.new_value) {
      const has_old = this.old_value != null

      if (!has_old || !this.old_value.includes(this.new_value[k])) {
        this.value.push({
          action: 'add',
          value: this.new_value[k],
        })
      }
    }

    for (const k in this.old_value) {
      const has_new = this.new_value != null

      if (!has_new || !this.new_value.includes(this.old_value[k])) {
        this.value.push({
          action: 'remove',
          value: this.old_value[k],
        })
      }
    }
  }

  public dmaName(id: number) {
    return dmaCodes.find(r => r.value == id)!.name
  }

  public inventoryName(inventory: string) {
    const val = Inventory.module.type_options.find(
      (i: any) => i.value == inventory,
    )
    return val ? val.name : inventory
  }

  public async creativeName(creative: string) {
    return await Creative.module
      .searchOptions({
        value: creative,
      })
      .then(response => response[0].name)
  }

  public created() {
    if (this.key_name == 'targeting') {
      this.parseTargetingChanges()
    } else if (this.key_name == 'creative_ids') {
      this.parseCreativeChanges()
    }
  }
}
