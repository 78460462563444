



































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Creative from '@/models/Creative'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import CreativeForm from '@/pages/Creative/components/CreativeForm.vue'
import ViewModel from '@/models/ViewModel'
import SelectOption from '@/models/interface/SelectOption'
import CreativeName from '@/components/CreativePicker/CreativeName.vue'

@Component({
  components: {
    SelectPicker,
    CreativeForm,
    CreativeName,
  },
})
export default class CreativePicker extends ViewModel {
  @Prop()
  public advertiser_id!: string;

  @Prop({ default: true })
  public edit!: boolean;

  @Prop({ default: 30 })
  public duration!: number;

  @Prop({ default: false })
  public create_panel!: boolean;

  @Prop({ default: 'Creatives' })
  public label!: string;

  @Prop({ default: () => [] })
  public value!: string[];

  public local_value: string[] = [];

  public show_sidebar: boolean = false;

  public update: boolean = true;

  public uuid: string = '';

  public ready: boolean = false;

  public options: SelectOption[] = [];

  public busy: boolean = false;

  public get can_create() {
    return this.create_panel && this.user.can('creative', 'edit')
  }

  public get panel_callback(): any {
    return this.layout.side_panel_callback.creative
  }

  @Watch('local_value')
  public onChangeValue() {
    this.update = false
    if (this.local_value == null) {
      this.$emit('input', [])
    } else {
      this.$emit('input', this.local_value)
    }
  }

  @Watch('value')
  public onChangeProp() {
    if (this.update) {
      if (this.value == null) {
        this.local_value = []
      } else {
        this.local_value = this.value
      }
    }
    this.update = true
  }

  @Watch('panel_callback')
  public onCallback() {
    if (this.uuid == this.layout.side_panel_id && this.panel_callback) {
      const values = this.value.slice(0)
      values.push(this.panel_callback)
      this.update = true
      this.$emit('input', values)

      this.layout.clearSidePanelCallback()
    }
  }

  public toggleSideBar() {
    this.layout.showSidePanel({
      name: 'creative',
      id: this.uuid,
      data: {
        advertiser_id: this.advertiser_id,
        id: this.uuid,
      },
    })
  }

  public mounted() {
    this.uuid = this.randomUUID()
  }

  public created() {
    if (this.edit) {
      if (
        !this.value
        || (Array.isArray(this.value) && this.value.length == 0)
      ) {
        this.ready = true
      } else {
        this.busy = true
        Creative.module
          .searchOptions({
            value: this.value,
          })
          .then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
      }
    } else {
      this.ready = true
    }
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    Creative.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
        owner_id: this.advertiser_id,
        type: this.duration,
      })
      .then(response => {
        this.options = response
        this.busy = false
        this.ready = true
      })
  }
}
