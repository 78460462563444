/** @format */

import Api from '@/models/Api'
import store from '@/store'
import CreativeModule from '@/store/model/CreativeModule'
import { getModule } from 'vuex-module-decorators'
import { VASTClient, VastMediaFile } from 'vast-client'
import ApiParameters from '@/models/interface/ApiParameters'
import Company from './Company'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'
import SelectOption from './interface/SelectOption'

export default class Creative {
  public id: string | null = null

  public name: string = ''

  public advertiser_id: string | null = null

  public type: string = 'vast'

  public duration: number = 30000

  public preview_url: string | null = null

  public vast_url: string | null = null

  public destination_url: string | null = null

  public created_at: string | null = null

  public updated_at: string | null = null

  public ready: boolean = true

  private _advertiser: Company | null = null

  get advertiser(): Company | null {
    if (
      this.advertiser_id
      && (this._advertiser == null || this._advertiser.id != this.advertiser_id)
    ) {
      this._advertiser = Company.find(this.advertiser_id)
    }
    return this._advertiser
  }

  public save(asset: any = null, onProgress: any = null) {
    const api = new Api(true, {
      onUploadProgress: (progressEvent: any) => {
        if (onProgress) onProgress(progressEvent)
      },
    })

    const data: ApiParameters = {
      name: this.name,
      type: this.type,
      advertiser_id: this.advertiser_id,
      duration: this.duration > 1000 ? this.duration : this.duration * 1000,
      asset_file: asset,
      destination_url: this.destination_url,
      vast_url: this.vast_url,
    }

    if (asset) {
      delete data.vast_url
    } else {
      delete data.asset_file
      delete data.destination_url
    }

    if (this.type == 'video') delete data.vast_url

    if (this.id) {
      return api
        .form(`creative/${this.id}`, data)
        .then(this.onSave)
        .catch(this.onError)
    }
    return api
      .form('creative', data)
      .then(this.onSave)
      .catch(this.onError)
  }

  public delete() {
    const api = new Api()

    return api
      .delete(`creative/${this.id}`, {})
      .then(this.onDelete)
      .catch(this.onError)
  }

  public updateTag() {
    const api = new Api()

    return api
      .post(`creative/${this.id}/tag`, {})
      .then(this.onTagUpdate)
      .catch(this.onError)
  }

  private onTagUpdate(response: any) {
    const creatives = Creative.toObject(response.data.result.creative)

    WebMessage.success(
      `Generating new TAG for creative "${creatives.name}"!`,
    )

    return response
  }

  private onSave(response: any) {
    const creatives = Creative.toObject(response.data.result.creative)

    WebMessage.success(`Creative "${creatives.name}" saved!`)

    return response
  }

  private onDelete(response: any) {
    const creatives = Creative.filter(response.data.result.deleted)

    let message

    if (creatives.length == 1) {
      message = `Entity "${creatives[0].name}" deleted!`
    } else {
      message = 'Entity deleted!'
    }

    WebMessage.success(message)

    Creative.module.delete(creatives)

    return response
  }

  private onError(error: any) {
    return error
  }

  private getUrl(): string {
    // Use VAST TAG if type is VAST
    if (this.type == 'vast' && this.vast_url) return this.vast_url
    // If Google Hosted, use preview URL
    if (this.preview_url) return this.preview_url
    // If Revvid Hosted Use static TAG
    if (this.type == 'video' && this.id) return `https://cdn.revvidmedia.com/videos/${this.id}/tag.xml`

    return ''
  }

  public test() {
    // Skip Validation If new Revvid Hosted
    if (this.type == 'video' && !this.id) {
      return new Promise(resolve => {
        resolve(true)
      })
    }

    const vastClient = new VASTClient()
    const url: string = this.getUrl()

    const valid = /^(?:https:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.{}%]+$/.test(
      url.replace('http:', 'https:'),
    )

    if (valid && url) return vastClient.get(url.replace('http:', 'https:'))
    return new Promise(() => {
      throw 'Invalide Tag'
    })
  }

  public inspect(mode: string = 'flow') {
    const url = this.getUrl()

    if (!url) return

    if (mode == 'google') {
      window.open(
        `https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/vastinspector?tag=${
          encodeURIComponent(url.replace('http:', 'https:'))}`,
        '_blank',
      )
    } else {
      window.open(
        `https://flowplayer.com/developers/tools/ad-tester?ad=0&custom=${
          encodeURIComponent(url.replace('http:', 'https:'))
        }&release_channel=stable`,
        '_blank',
      )
    }
  }

  public preview() {
    const formats = ['video/webm', 'video/mp4']
    return this.test()
      .then((r: any) => {
        if (r.ads && r.ads.length > 0) {
          const ad = r.ads[Math.floor(Math.random() * r.ads.length)]

          const files = ad.creatives[Math.floor(Math.random() * ad.creatives.length)]
            .mediaFiles

          return files.reduce((carry: any, current: any) => {
            if (!carry) return current
            if (
              current.width > carry.width
              && formats.includes(current.mimeType)
            ) return current
            if (
              current.width == carry.width
              && formats.includes(current.mimeType)
              && current.deliveryType == 'STREAMING'
            ) return current
            return carry
          }, null)
        }
        throw 'Invalide Tag'
      })
      .catch(() => {
        WebMessage.error(
          'Could not load the preview, the tag returned an empty response.',
          [
            {
              text: 'Inspect',
              action: () => {
                this.inspect()
              },
            },
          ],
        )
      })
  }

  public static toObject(data: any, cache: boolean = true): Creative {
    const creative = new Creative()

    creative.id = data.id
    creative.name = data.name
    creative.advertiser_id = data.advertiser_id
    creative.type = data.type
    creative.duration = data.duration
    creative.preview_url = data.preview_url
    creative.vast_url = data.vast_url
    creative.destination_url = data.destination_url
    creative.created_at = data.created_at
    creative.updated_at = data.updated_at
    creative.ready = data.ready

    if (data.advertiser) Company.toObject(data.advertiser)

    //  Cache Object List
    if (cache) Creative.module.update(creative)

    return creative
  }

  public static toObjectList(data: any, cache: boolean = true): Creative[] {
    const creatives = new Array<Creative>()
    data.forEach((value: any) => {
      const creative = Creative.toObject(value, false)
      creatives.push(creative)
    })

    //  Cache Object List
    if (cache) Creative.module.update(creatives)

    return creatives
  }

  public toOption(): SelectOption {
    return new SelectOption(
      this.name,
      this.id,
      this.advertiser_id,
      this.duration > 16000 ? '30' : '15',
    )
  }

  // State Management
  public static get module(): CreativeModule {
    if (!store.hasModule('creative')) {
      store.registerModule('creative', CreativeModule)
    }
    const m = getModule(CreativeModule)
    if (!m.synchronized) {
      m.syncOptions()
    }

    return m
  }

  public static find(id: string): Creative | null {
    const o = Creative.module.data.find(creative => creative.id === id)
    return o instanceof Creative ? o : null
  }

  public static filter(filter: any): Creative[] {
    if (Array.isArray(filter)) {
      return Creative.module.data.filter(
        creative => creative.id && filter.includes(creative.id),
      )
    }
    return Creative.module.data.filter(filter)
  }

  public static async get(id: string): Promise<Creative | null> {
    return Creative.module.find(id)
  }

  public static async paginate(options: PaginateOptions) {
    return Creative.module.paginate(options)
  }
}
