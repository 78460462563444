import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import BookingItem from '@/models/BookingItem'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'booking_item',
})
export default class BookingItemModule extends VuexModule {
    public data: BookingItem[] = [];

    public options: SelectOption[] = [];

    public synchronized: boolean = false;

    public get api() {
      return new Api(false)
    }

    @Mutation
    public setSynchronized(status: boolean) {
      this.synchronized = status
    }

    @Mutation
    public update(booking_items: BookingItem | BookingItem[]) {
      let data: Array<BookingItem> = []
      if (booking_items instanceof BookingItem) {
        data.push(booking_items)
      } else {
        data = booking_items
      }

      let result = this.data

      data.forEach((booking_item: BookingItem) => {
        let found: boolean = false
        result = result.map((obj: BookingItem) => {
          if (obj.id == booking_item.id) {
            found = true
            return booking_item
          }
          return obj
        })

        if (!found) {
          result.push(booking_item)
        }
      })

      this.data = result
    }

    @Mutation
    public delete(booking_items: BookingItem | BookingItem[]) {
      let data: Array<BookingItem> = []
      if (booking_items instanceof BookingItem) {
        data.push(booking_items)
      } else {
        data = booking_items
      }

      let result = this.data

      data.forEach((booking_item: BookingItem) => {
        result = result.filter((obj: BookingItem) => obj.id != booking_item.id)
      })

      this.data = result
    }

    @Action
    public async find(id: string): Promise<BookingItem | null> {
      return new Promise(resolve => {
        const o = this.data.find(booking_item => booking_item.id === id)

        if (o instanceof BookingItem) {
          resolve(o)
        } else {
          return this.api.get(`booking_item/${id}`)
            .then(response => {
              // Parse & cache data
              const data = BookingItem.toObject(response.data.result.booking_item)

              if (data instanceof BookingItem) {
                resolve(data)
              }
            }).catch(() => {
              resolve(null)
            })
        }
      })
    }

    @Action
    public async paginate(options: PaginateOptions) {
      return this.api.get('booking_items/paginate', options)
        .then(response => {
          // Parse & cache data
          const data = BookingItem.toObjectList(response.data.result.booking_items)

          return {
            records: response.data.result.records,
            data,
          }
        }).catch(() => ({
          records: 0,
          data: [],
        }))
    }
}
