import Api from '@/models/Api'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import BookingModule from '@/store/model/BookingModule'
import Company from './Company'
import DatePickerDate from './DatePickerDate'
import BookingItem from './BookingItem'
import User from './User'
import Audit from './Audit'
import WebMessage from './WebMessage'
import PaginateOptions from './interface/PaginateOptions'

export default class Booking {
    public id: string | null = null;

    public name: string = '';

    public admanager_id: string | null = null;

    public advertiser_id: string = '';

    public agency_id: string | null = null;

    public station_id: string | null = null;

    public account_executive_id: string | null = null;

    public date: DatePickerDate = new DatePickerDate(moment().format('YYYY-MM-DD'));

    public status: string = 'pending';

    public sync_status: string = 'review';

    public action: string = 'reserve';

    public cost: number = 0;

    public impressions: number = 0;

    public start_at: string | null = null;

    public end_at: string | null = null;

    public billing_contract: number | null = null;

    public audits: Audit[] = [];

    public message: string = '';

    public get loading(): boolean {
      return this.items!.some((item: BookingItem) => item.forecast.loading)
    }

    private _items: BookingItem[] | null = null;

    public get items(): BookingItem[] | null {
      if (!this._items) {
        this._items = BookingItem.filter((o: BookingItem) => this.id == o.booking_id)
        let count = 1
            this._items!.forEach(i => {
              i.number = count++
            })
      }
      return this._items
    }

    public addItem(item: BookingItem | null = null) {
      const check = this.items == null

      if (!item) {
        item = new BookingItem()
        item.number = this._items!.length + 1
        if (item.number == 1) {
          item.name = `${this.name} Line Item`
        } else {
          item.name = `${this.name} Line Item ${item.number}`
        }
      } else {
        item.number = this._items!.length + 1
      }

        this._items!.push(item)

        this.editItem(item.number)
    }

    public cloneItem(number: number) {
      const item = Object.assign(new BookingItem(), this.items!.find((i: BookingItem) => i.number == number))
      item.id = null
      item.name += ' Copy'
      this.addItem(item)
    }

    public removeItem(number: number) {
      if (!this._items) {
        Vue.set(this, '_items', new Array<BookingItem>())
      }

        this._items!.splice(number - 1, 1)

        let count = 1
        this.items!.forEach(i => {
          i.number = count++
        })
    }

    public editItem(number: number) {
        this.items!.forEach(i => {
          i._showDetails = (i.number == number && i._showDetails != true)
        })
    }

    public moveItemUp(number: number) {
      if (number == 1) return

        this._items![number - 1].number--
        this._items![number - 2].number++

        this._items!.sort((a, b) => a.number - b.number)
    }

    public moveItemDown(number: number) {
      if (number == this._items!.length) return

        this._items![number - 1].number++
        this._items![number].number--

        this._items!.sort((a, b) => a.number - b.number)
    }

    public get budget(): number {
      return this.impressions * this.cost / 1000
    }

    private _account_executive: User | null = null;

    get account_executive(): User | null {
      if (this.account_executive_id && (this._account_executive == null || this._account_executive.id != this.account_executive_id)) {
        this._account_executive = User.find(this.account_executive_id)
      }

      return this._account_executive
    }

    private _advertiser: Company | null = null;

    get advertiser(): Company | null {
      if (this.advertiser_id && (this._advertiser == null || this._advertiser.id != this.advertiser_id)) {
        this._advertiser = Company.find(this.advertiser_id)
      }
      return this._advertiser
    }

    private _agency: Company | null = null;

    public get agency(): Company | null {
      if (this.agency_id && (this._agency == null || this._agency.id != this.agency_id)) {
        this._agency = Company.find(this.agency_id)
      }

      return this._agency
    }

    private _station: Company | null = null;

    public get station(): Company | null {
      if (this.station_id && (this._station == null || this._station.id != this.station_id)) {
        this._station = Company.find(this.station_id)
      }

      return this._station
    }

    public validate() {
      if (this.station_id) this._station = Company.find(this.station_id)
      else this._station = null

      if (this.agency_id) this._agency = Company.find(this.agency_id)
      else this._agency = null

      if (this.advertiser_id) this._advertiser = Company.find(this.advertiser_id)
      else this._advertiser = null

      this.impressions = 0
      let k: any
      for (k in this.items) {
            this.items![k].validate(this)
            this.impressions += this.items![k].impressions
      }
    }

    public get forecast_status() {
      if (!this.items) {
        return 'error'
      }
      const ok = this.items.some((item: BookingItem) => item.forecast_status == 'ok')
      const warning = this.items.some((item: BookingItem) => item.forecast_status == 'warning')
      const error = this.items.some((item: BookingItem) => item.forecast_status == 'error')
      if (error && !ok && !warning) {
        return 'error'
      } if (!error && !warning) {
        return 'ok'
      }
      return 'warning'
    }

    public checkItemCreatives() {
      if (!this.items) return false
      return !this.items.some((item: BookingItem) => item.creative_ids.length == 0 && item.action != 'reserve')
    }

    public updateForecast() {
      if (this.items) {
        let k: any
        for (k in this.items) {
          this.items[k].updateForecast()
        }
      }
    }

    public save() {
      const api = new Api()

      const data = {
        name: this.name,
        billing_contract: this.billing_contract,
        agency_id: this.agency_id,
        station_id: this.station_id,
        account_executive_id: this.account_executive_id,
        advertiser_id: this.advertiser_id,
        impressions: this.impressions,
        action: this.action,
        cost: this.cost,
        start_at: this.date.start,
        end_at: this.date.end,
        status: this.status,
        items: this.items!.map((item: BookingItem) => item.buildParams()),
      }

      if (this.id) {
        return api.put(`booking/${this.id}`, data).then(response => {
          const booking = this.onUpdate(response)

          WebMessage.success(`Order "${booking.name} updated!`)

          return response
        }).catch(this.onError)
      }
      return api.post('booking', data).then(response => {
        const booking = this.onUpdate(response)

        let message = `Order "${booking.name}`
        message += this.action == 'book' ? ' booked!' : ' reserved!'
        WebMessage.success(message)

        return response
      }).catch(this.onError)
    }

    public book() {
      const api = new Api()
      return api.put(`booking/${this.id}/book`, {
        message: this.message,
      }).then(response => {
        const booking = this.onUpdate(response)
        WebMessage.success(`Booking "${booking.name}" confirmed!`)

        return response
      }).catch(this.onError)
    }

    public approve() {
      const api = new Api()
      return api.put(`booking/${this.id}/approve`, {}).then(response => {
        const booking = this.onUpdate(response)
        WebMessage.success(`Booking "${booking.name}" approved!`)

        return response
      }).catch(this.onError)
    }

    public reject() {
      const api = new Api()
      return api.put(`booking/${this.id}/reject`, {
        message: this.message,
      }).then(response => {
        const booking = this.onUpdate(response)
        WebMessage.success(`Booking "${booking.name}" rejected!`)

        return response
      }).catch(this.onError)
    }

    public start() {
      const api = new Api()
      return api.put(`booking/${this.id}/start`, {}).then(response => {
        const booking = this.onUpdate(response)
        WebMessage.success(`Booking "${booking.name}" status set to active!`)
        return response
      }).catch(this.onError)
    }

    public pause() {
      const api = new Api()
      return api.put(`booking/${this.id}/pause`, {}).then(response => {
        const booking = this.onUpdate(response)
        WebMessage.success(`Booking "${booking.name}" paused!`)

        return response
      }).catch(this.onError)
    }

    public delete() {
      const api = new Api()

      return api.delete(`booking/${this.id}`, {}).then(this.onDelete).catch(this.onError)
    }

    public getAuditRecords() {
      const api = new Api()
      return api.get(`booking/${this.id}/audit`, {}).then((response: any) => {
        this.audits = Audit.toObjectList(response.data.result.audits)
        for (const key in this.audits) {
          if (this.audits[key].new_values.targeting) this.audits[key].new_values.targeting = JSON.parse(this.audits[key].new_values.targeting)
          if (this.audits[key].old_values.targeting) this.audits[key].old_values.targeting = JSON.parse(this.audits[key].old_values.targeting)

          if (this.audits[key].new_values.creative_ids) this.audits[key].new_values.creative_ids = JSON.parse(this.audits[key].new_values.creative_ids)
          if (this.audits[key].old_values.creative_ids) this.audits[key].old_values.creative_ids = JSON.parse(this.audits[key].old_values.creative_ids)
        }
        return response
      }).catch(this.onError)
    }

    private onUpdate(response: any) {
      const booking = Booking.toObject(response.data.result.booking)

      return booking
    }

    private onDelete(response: any) {
      const bookings = Booking.filter(response.data.result.deleted)

      let message

      if (bookings.length == 1) {
        message = `Booking "${bookings[0].name}" deleted!`
      } else {
        message = 'Bookings deleted!'
      }

      WebMessage.success(message)

      Booking.module.delete(bookings)

      return response
    }

    private onError(error: any) {
      return error
    }

    public static toObject(data: any, cache: boolean = true): Booking {
      const booking = new Booking()

      booking.id = data.id
      booking.name = data.name
      booking.admanager_id = data.admanager_id
      booking.advertiser_id = data.advertiser_id
      booking.agency_id = data.agency_id
      booking.station_id = data.station_id
      booking.account_executive_id = data.account_executive_id
      booking.status = data.status
      booking.sync_status = data.sync_status
      booking.action = data.action
      booking.impressions = data.impressions
      booking.start_at = data.start_at
      booking.end_at = data.end_at
      booking.billing_contract = data.billing_contract
      booking.audits = data.audits
      booking.date = new DatePickerDate(booking.start_at, booking.end_at)

      if (data.agency) {
        Company.toObject(data.agency)
      }

      if (data.station) {
        Company.toObject(data.station)
      }

      if (data.advertiser) {
        Company.toObject(data.advertiser)
      }

      if (data.account_executive) {
        User.toObject(data.account_executive)
      }

      if (data.items) {
        const current_items = BookingItem.module.data.filter(i => i.booking_id == booking.id)

        if (current_items) {
          BookingItem.module.delete(current_items)
        }

        BookingItem.toObjectList(data.items)
      }

      //  Cache Object List
      if (cache) Booking.module.update(booking)

      return booking
    }

    public static toObjectList(data: any, cache: boolean = true): Booking[] {
      const bookings = new Array<Booking>()
      data.forEach((value: any) => {
        const booking = Booking.toObject(value, false)
        bookings.push(booking)
      })

      //  Cache Object List
      if (cache) Booking.module.update(bookings)

      return bookings
    }

    /// State Management
    public static get module(): BookingModule {
      if (!store.hasModule('booking')) {
        store.registerModule('booking', BookingModule)
      }

      return getModule(BookingModule)
    }

    public static find(id: string): Booking | null {
      const o = Booking.module.data.find(booking => booking.id === id)
      return o instanceof Booking ? o : null
    }

    public static filter(filter: string[]): Booking[] {
      if (Array.isArray(filter)) {
        return Booking.module.data.filter(booking => booking.id && filter.includes(booking.id))
      }
      return Booking.module.data.filter(filter)
    }

    public static async get(id: string): Promise<Booking | null> {
      return Booking.module.find(id)
    }

    public static async paginate(options: PaginateOptions) {
      return Booking.module.paginate(options)
    }
}
