/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Booking from '@/models/Booking'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'booking',
})
export default class BookingModule extends VuexModule {
  public data: Booking[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(bookings: Booking | Booking[]) {
    let data: Array<Booking> = []
    if (bookings instanceof Booking) {
      data.push(bookings)
    } else {
      data = bookings
    }

    let result = this.data

    data.forEach((booking: Booking) => {
      let found: boolean = false
      result = result.map((obj: Booking) => {
        if (obj.id == booking.id) {
          found = true
          return booking
        }
        return obj
      })

      if (!found) {
        result.push(booking)
      }
    })

    this.data = result
  }

  @Mutation
  public delete(bookings: Booking | Booking[]) {
    let data: Array<Booking> = []
    if (bookings instanceof Booking) {
      data.push(bookings)
    } else {
      data = bookings
    }

    let result = this.data

    data.forEach((booking: Booking) => {
      result = result.filter((obj: Booking) => obj.id != booking.id)
    })

    this.data = result
  }

  @Action
  public async find(id: string): Promise<Booking | null> {
    return new Promise(resolve => {
      const o = this.data.find(booking => booking.id === id)

      if (o instanceof Booking) {
        resolve(o)
      } else {
        return this.api
          .get(`booking/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Booking.toObject(response.data.result.booking)

            if (data instanceof Booking) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('bookings/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = Booking.toObjectList(response.data.result.bookings)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }
}
