export default class Targeting {
    public devices: string[] = [
      'ctv',
      'desktop',
      'mobile',
    ];

    public inventory: string[] = [
      'sportstream',
      'sportstreamtv',
      'sportstreamlive',
      'newstream',
    ];

    public publisher: string[] = [];

    public include_states: string[] = [];

    public exclude_states: string[] = [];

    public include_dmas: string[] = [];

    public exclude_dmas: string[] = [];

    public creative_length: number = 30;

    public live: number = 1;

    public static toObject(data: any): Targeting {
      const targeting = new Targeting()
      targeting.devices = data.devices
      targeting.inventory = data.inventory
      targeting.publisher = data.publisher
      targeting.include_states = data.include_states
      targeting.exclude_states = data.exclude_states
      targeting.include_dmas = data.include_dmas
      targeting.exclude_dmas = data.exclude_dmas
      targeting.creative_length = data.creative_length

      return targeting
    }
}
