import store from '@/store'
import moment from 'moment'
import BookingItemModule from '@/store/model/BookingItemModule'
import { getModule } from 'vuex-module-decorators'
import Company from './Company'
import DatePickerDate from './DatePickerDate'
import Creative from './Creative'
import Targeting from './Targeting'
import Forecast from './Forecast'
import Booking from './Booking'
import PaginateOptions from './interface/PaginateOptions'

export default class BookingItem {
    public id: string | null = null;

    public name: string = '';

    public admanager_id: string | null = null;

    public advertiser_id: string | null = null;

    public booking_id: string | null = null;

    public date: DatePickerDate = new DatePickerDate(moment().format('YYYY-MM-DD'));

    public creative_ids: string[] = [];

    public status: string = 'pending';

    public sync_status: string = 'review';

    public action: string = 'book';

    public impressions: number = 0;

    public start_at: string | null = null;

    public end_at: string | null = null;

    public number: number = 0;

    public cost: number = 0;

    public _showDetails: boolean = false;

    public targeting: Targeting = new Targeting();

    public forecast: Forecast = new Forecast();

    public parent_impressions: number = 0;

    public get budget(): number {
      return this.impressions * this.cost / 1000
    }

    private _advertiser: Company | null = null;

    get advertiser(): Company | null {
      if (this.advertiser_id && (this._advertiser == null || this._advertiser.id != this.advertiser_id)) {
        this._advertiser = Company.find(this.advertiser_id)
      }
      return this._advertiser
    }

    private _creatives: Creative[] = [];

    public get creatives(): Creative[] {
      if (!this._creatives) {
        this._creatives = Creative.filter(this.creative_ids)
      }

      return this._creatives
    }

    public validate(booking: Booking) {
      const creative_ids = [] as string[]
      for (const k in this.creative_ids) {
        // TODO: Ensure asigned creatives belongs to Advertiser & match selected Creative Length

        creative_ids.push(this.creative_ids[k])
      }

      if (this.creative_ids.length != creative_ids.length) {
        this.creative_ids = creative_ids
      }

      this.action = booking.action

      this._creatives = Creative.filter(this.creative_ids)
    }

    public updateForecast() {
      this.forecast.include_dmas = this.targeting.include_dmas
      this.forecast.exclude_dmas = this.targeting.exclude_dmas
      this.forecast.include_states = this.targeting.include_states
      this.forecast.exclude_states = this.targeting.exclude_states
      this.forecast.include_zipcodes = ''
      this.forecast.exclude_zipcodes = ''
      this.forecast.dimensions = ['live_events']
      this.forecast.date = this.date
      this.forecast.devices = this.targeting.devices
      this.forecast.inventory = this.targeting.inventory
      this.forecast.run()
    }

    public get forecast_status() {
      if (this.forecast.result.impressions == 0) {
        return 'error'
      } if ((this.impressions > 0 && this.forecast.result.impressions < this.impressions) || (this.impressions == 0 && this.forecast.result.impressions < this.parent_impressions)) {
        return 'warning'
      } if (this.forecast.result.impressions == 0) {
        return 'ok'
      }
    }

    public buildParams() {
      return {
        id: this.id,
        name: this.name,
        impressions: this.impressions,
        action: this.action,
        cost: this.cost,
        start_at: this.date.start,
        end_at: this.date.end,
        creative_ids: this.creative_ids,
        targeting: this.targeting,
        status: this.status,
      }
    }

    public copyForecast(forecast: Forecast) {
      this.targeting.devices = forecast.devices
      this.targeting.inventory = forecast.inventory
      this.targeting.publisher = forecast.publishers
      this.targeting.include_states = forecast.include_states
      this.targeting.exclude_states = forecast.exclude_states
      this.targeting.include_dmas = forecast.include_dmas
      this.targeting.exclude_dmas = forecast.exclude_dmas
      this.start_at = forecast.date.start
      this.end_at = forecast.date.end
      this.date = new DatePickerDate(this.start_at, this.end_at)
    }

    public static toObject(data: any, cache: boolean = true): BookingItem {
      const item = new BookingItem()

      item.id = data.id
      item.name = data.name
      item.advertiser_id = data.advertiser_id
      item.booking_id = data.booking_id
      item.creative_ids = data.creative_ids.slice(0)
      item.status = data.status
      item.sync_status = data.sync_status
      item.action = data.action
      item.impressions = data.impressions
      item.start_at = data.start_at
      item.end_at = data.end_at
      item.cost = data.cost
      item.admanager_id = data.admanager_id
      item.date = new DatePickerDate(item.start_at, item.end_at)
      item.targeting = Targeting.toObject(data.targeting)

      //  Cache Object List
      if (cache) BookingItem.module.update(item)

      return item
    }

    public static toObjectList(data: any, cache: boolean = true): BookingItem[] {
      const items = new Array<BookingItem>()
      data.forEach((value: any) => {
        const item = BookingItem.toObject(value, false)
        items.push(item)
      })

      //  Cache Object List
      if (cache) BookingItem.module.update(items)

      return items
    }

    // State Management
    public static get module(): BookingItemModule {
      if (!store.hasModule('booking_item')) {
        store.registerModule('booking_item', BookingItemModule)
      }

      return getModule(BookingItemModule)
    }

    public static find(id: string): BookingItem | null {
      const o = BookingItem.module.data.find(booking_item => booking_item.id === id)
      return o instanceof BookingItem ? o : null
    }

    public static filter(filter: any): BookingItem[] {
      if (Array.isArray(filter)) {
        return BookingItem.module.data.filter(booking_item => booking_item.id && filter.includes(booking_item.id))
      }
      return BookingItem.module.data.filter(filter)
    }

    public static async get(id: string): Promise<BookingItem | null> {
      return BookingItem.module.find(id)
    }

    public static async paginate(options: PaginateOptions) {
      return BookingItem.module.paginate(options)
    }
}
