




































































































































































































































































































































































































































































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Booking from '@/models/Booking'
import WebMessage from '@/models/WebMessage'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import BookingHistoryProperty from './components/BookingHistoryProperty.vue'

@Component({
  components: {
    Widget,
    BookingHistoryProperty,
    IconAction,
    SearchInput,
  },
})
export default class BookingHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public booking: Booking = new Booking()

  public sort_by: string = 'updated_at'

  public sort_desc: boolean = true

  public query: string[] = []

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public view_history: boolean = false

  public loading: boolean = false

  public modal: any = {
    approve: false,
    reject: false,
    pause: false,
    start: false,
    book: false,
    delete: false,
  }

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
      show: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      show: true,
    },
    {
      key: 'agency_id',
      label: 'Agency',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'station_id',
      label: 'Station',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'account_executive_id',
      label: 'Account Executive',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'advertiser_id',
      label: 'Advertiser',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'created_at',
      label: 'Created At',
      sortable: true,
      class: 'text-center',
      show: false,
    },
    {
      key: 'updated_at',
      label: 'Update At',
      sortable: true,
      class: 'text-center',
      show: true,
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      show: true,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
      show: true,
    },
  ]

  public query_settings: any = {
    company_fields: [
      {
        name: 'agency',
        key: 'agency_id',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
      },
    ],
    user_fields: [
      {
        name: 'account executive',
        key: 'account_executive_id',
      },
    ],
    custom_fields: [
      {
        name: 'station:n/a',
        value: 'station_id:',
      },
      {
        name: 'status:reserved',
        value: 'action:reserve',
      },
      {
        name: 'status:rejected',
        value: 'sync_status:rejected',
      },
      {
        name: 'status:pending review',
        value: 'sync_status:review',
      },
      {
        name: 'status:active',
        value: 'status:active',
      },
      {
        name: 'status:paused',
        value: 'status:paused',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public bookings(context: any) {
    this.loading = true
    return Booking.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public createBooking() {
    this.$router.push('/app/booking')
  }

  public confirmDelete(booking: Booking) {
    this.booking = booking
    this.modal.delete = true
  }

  public deleteBooking() {
    this.booking.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmBook(booking: Booking) {
    if (!booking.checkItemCreatives()) {
      WebMessage.error(
        'Please add the creatives to the items before booking the order.',
      )
      return
    }
    this.booking = booking
    this.modal.book = true
  }

  public bookBooking() {
    this.booking.book().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmApprove(booking: Booking) {
    this.booking = booking
    this.modal.approve = true
  }

  public approveBooking() {
    this.booking.approve().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmReject(booking: Booking) {
    this.booking = booking
    this.modal.reject = true
  }

  public rejectBooking() {
    this.booking.reject().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmPause(booking: Booking) {
    this.booking = booking
    this.modal.pause = true
  }

  public pauseBooking() {
    this.booking.pause().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmStart(booking: Booking) {
    this.booking = booking
    this.modal.start = true
  }

  public startBooking() {
    this.booking.start().then(() => {
      this.dataTable.refresh()
    })
  }

  public editBooking(id: string) {
    this.$router.push(`/app/booking/${id}`)
  }

  public cloneBooking(id: string) {
    this.$router.push(`/app/booking?from=${id}`)
  }

  public viewHistory(booking: Booking) {
    this.booking = booking
    this.loading = true
    this.view_history = true
    this.booking.getAuditRecords().then(() => {
      this.loading = false
    })
  }

  public created() {
    this.records = this.bookings.length
  }
}
