

































































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Booking from '@/models/Booking'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import dmaCodes from '@/data/dma_options'
import moment from 'moment'
import BookingItem from '@/models/BookingItem'
import WebMessage from '@/models/WebMessage'
import ViewModel from '@/models/ViewModel'
import DatePickerDate from '@/models/DatePickerDate'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import CreativeName from '@/components/CreativePicker/CreativeName.vue'
import Inventory from '../../models/Inventory'
import Company from '../../models/Company'
import User from '../../models/User'
import BookingItemForm from './components/BookingItemForm.vue'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    BookingItemForm,
    UserPicker,
    CompanyPicker,
    FormWizard,
    TabContent,
    FormInput,
    IconAction,
    CreativeName,
  },
})
export default class BookingEdit extends ViewModel {
  @Prop({ default: null })
  public id!: string | null;

  public booking: Booking = new Booking();

  public busy = true;

  public loading: boolean = false;

  public step: number = 1;

  public asset: any = null;

  public min_date: string = moment().format('YYYY-MM-DD');

  public advertiser_ref: string = '';

  public action_options: any = [
    {
      name: 'Book',
      value: 'book',
    },
    {
      name: 'Reserve (Draft)',
      value: 'reserve',
    },
  ];

  public status_options: any = [
    {
      name: 'Active',
      value: 'active',
    },
    {
      name: 'Paused',
      value: 'paused',
    },
  ];

  public fields: any = [
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
    },
    {
      key: 'start_at',
      label: 'Flight',
      sortable: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
    },
    {
      key: 'creatives',
      label: 'Creatives',
      sortable: true,
    },
    {
      key: 'action',
      label: 'Action',
      sortable: false,
    },
  ];

  public created() {
    if (this.id) {
      Booking.get(this.id).then(booking => {
        if (booking instanceof Booking) this.booking = booking
        else return this.newBooking()

        if (this.booking.date.start) {
          this.min_date = this.booking.date.start
        }

        this.busy = false
      })
    } else {
      const { query } = this.$route

      if (query.from && typeof query.from === 'string') {
        Booking.get(query.from).then(booking => {
          if (booking instanceof Booking) this.booking = Object.assign(new Booking(), booking)
          else return this.newBooking()

          if (this.booking.date.start) {
            this.min_date = this.booking.date.start
          }

          this.booking.id = null
          this.booking.name += ' Copy'

          if (booking.items) {
            booking.items.forEach(i => {
              const item = Object.assign(new BookingItem(), i)
              item.id = null
              this.booking.addItem(item)
            })
          }

          this.newBooking()
        })
      } else {
        this.newBooking()
      }
    }
  }

  public newBooking() {
    if (this.system.forecastModel) {
      this.booking.start_at = this.system.forecastModel.date.start
      this.booking.end_at = this.system.forecastModel.date.end
      this.booking.date = new DatePickerDate(
        this.booking.start_at,
        this.booking.end_at,
      )

      this.booking.addItem()
      this.booking.items![0].copyForecast(this.system.forecastModel)

      this.system.updateState({
        name: 'forecast',
        data: null,
      })
    }

    if (!this.user.isSuperAdmin) {
      this.booking.agency_id = this.user.company_id

      if (this.user.isStation) {
        this.booking.station_id = this.user.station_id
      }

      if (!this.user.isAdmin) {
        this.booking.account_executive_id = this.user.id
      }
    }

    this.busy = false
  }

  public dmaName(id: number) {
    return dmaCodes.find(r => r.value == id)!.name
  }

  public inventoryName(inventory: string) {
    const val = Inventory.module.type_options.find((i: any) => i.value == inventory)
    return val ? val.name : inventory
  }

  public onSubmit() {
    if (!this.booking.checkItemCreatives()) {
      WebMessage.error(
        'Please add the creatives to the items before booking the order or change the status to "Reserve (Draft)" to save it withou creatives.',
      )
      return
    }
    this.loading = true
    this.booking
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Bookings' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'Bookings' })
  }

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public beforeChange() {
    if (this.step == 1) {
      this.busy = true
      if (this.booking.account_executive_id) User.get(this.booking.account_executive_id)
      return Company.get(this.booking.advertiser_id).then(() =>
        // @ts-ignore
        Company.get(this.booking.agency_id).then(() =>
          // @ts-ignore
          Company.get(this.booking.station_id).then(() => {
            if (!(this.booking.items!.length > 0)) this.booking.addItem()
            this.busy = false
            return true
          })))
    } if (this.step == 2) {
      this.booking.updateForecast()
    }

    if (this.step != 3) {
      this.booking.validate()
    }

    if (this.advertiser_ref != this.booking.advertiser_id) {
      this.booking.cost = this.booking.advertiser!.rate
      this.advertiser_ref = this.booking.advertiser_id
    }

    return true
  }
}
