





























































































































































import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import CreativePicker from '@/components/CreativePicker/CreativePicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import BookingItem from '@/models/BookingItem'
import Booking from '@/models/Booking'
import dmaCodes from '@/data/dma_options'
import stateCodes from '@/data/state_options'
import Creative from '@/models/Creative'
import ViewModel from '@/models/ViewModel'
import FormInput from '@/components/FormInput/FormInput.vue'
import Inventory from '../../../models/Inventory'

@Component({
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    CreativePicker,
    FormInput,
  },
})
export default class BookingItemForm extends ViewModel {
  @Prop({
    default: () => new Booking(),
  })
  public booking!: Booking;

  @Prop({
    default: () => new BookingItem(),
  })
  public item!: BookingItem;

  @Prop({
    default: '',
  })
  public advertiser_id!: string;

  @Prop({
    default: '',
  })
  public min_date!: string;

  public country_id: string = '231';

  public action_options: any = [
    {
      name: 'Book',
      value: 'book',
    },
    {
      name: 'Reserve (Draft)',
      value: 'reserve',
    },
  ];

  public status_options: any = [
    {
      name: 'Active',
      value: 'active',
    },
    {
      name: 'Paused',
      value: 'paused',
    },
  ];

  public get inventory_options() {
    return Inventory.module.type_options
  }

  public get device_options() {
    return Inventory.module.device_options
  }

  public get creative_type_options() {
    return Creative.module.type_options
  }

  public get state_options() {
    return stateCodes
  }

  public validateItem() {
    this.item.validate(this.booking)
  }

  public get dma_options() {
    return dmaCodes
  }
}
