/** @format */

import store from '@/store'
import {
  Module, VuexModule, Mutation, Action,
} from 'vuex-module-decorators'
import SelectOption from '@/models/interface/SelectOption'
import Api from '@/models/Api'
import Creative from '@/models/Creative'
import PaginateOptions from '@/models/interface/PaginateOptions'

@Module({
  dynamic: true, store, namespaced: true, name: 'creative',
})
export default class CreativeModule extends VuexModule {
  public data: Creative[] = []

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  public options: SelectOption[] = []

  public type_options = [
    new SelectOption('15s', '15'),
    new SelectOption('30s', '30'),
  ]

  public synchronized: boolean = false

  public get api() {
    return new Api(false)
  }

  @Mutation
  public setOptions(options: SelectOption[]) {
    this.options = options
  }

  @Mutation
  public setSynchronized(status: boolean) {
    this.synchronized = status
  }

  @Mutation
  public update(creatives: Creative | Creative[]) {
    // Set input to array
    let data: Array<Creative> = []
    if (creatives instanceof Creative) {
      data.push(creatives)
    } else {
      data = creatives
    }

    let result = this.data

    // Update Module Data
    data.forEach((creative: Creative) => {
      let found: boolean = false
      result = result.map((obj: Creative) => {
        if (obj.id == creative.id) {
          found = true
          return creative
        }
        return obj
      })

      if (!found) {
        result.push(creative)
      }
    })

    this.data = result

    // Update Module Options
    let { options } = this
    data.forEach((creative: Creative) => {
      let found: boolean = false
      options = options.map((o: SelectOption) => {
        if (o.value == creative.id) {
          found = true
          return creative.toOption()
        }
        return o
      })

      if (!found) {
        options.push(creative.toOption())
      }
    })

    this.options = options
  }

  @Mutation
  public delete(creatives: Creative | Creative[]) {
    // Set input to array
    let data: Array<Creative> = []
    if (creatives instanceof Creative) {
      data.push(creatives)
    } else {
      data = creatives
    }

    let result = this.data

    // Remove Object from Module Data
    data.forEach((creative: Creative) => {
      result = result.filter((obj: Creative) => obj.id != creative.id)
    })

    this.data = result

    // Remove Object from Module Options
    let { options } = this
    data.forEach((creative: Creative) => {
      options = options.filter((obj: SelectOption) => obj.value != creative.id)
    })

    this.options = options
  }

  @Action
  public async find(id: string): Promise<Creative | null> {
    return new Promise(resolve => {
      const o = this.data.find(creative => creative.id === id)

      if (o instanceof Creative) {
        resolve(o)
      } else {
        return this.api
          .get(`creative/${id}`)
          .then(response => {
            // Parse & cache data
            const data = Creative.toObject(response.data.result.creative)

            if (data instanceof Creative) {
              resolve(data)
            }
          })
          .catch(() => {
            resolve(null)
          })
      }
    })
  }

  @Action
  public async paginate(options: PaginateOptions) {
    return this.api
      .get('creatives/paginate', options)
      .then(response => {
        // Parse & cache data
        const data = Creative.toObjectList(response.data.result.creatives)

        return {
          records: response.data.result.records,
          data,
        }
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  /**
   * @deprecated No longer needed after Elasticsearch implementation. Use searchOptions instead.
   */
  @Action
  public async syncOptions() {
    this.setSynchronized(true)
    return this.api
      .get('creatives/option')
      .then(response => {
        // Parse & cache data
        const data = SelectOption.toObjectList(response.data.result.options)

        this.context.commit('setOptions', data)
      })
      .catch(() => ({
        records: 0,
        data: [],
      }))
  }

  @Action
  public async searchOptions(query: any) {
    return this.api
      .get('creatives/search/option', query)
      .then(response => SelectOption.toObjectList(response.data.result.options))
      .catch(() => [])
  }
}
