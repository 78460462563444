









































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import Creative from '@/models/Creative'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import { VastMediaFile } from 'vast-client'

@Component({
  components: {
    CompanyPicker,
    IconAction,
    SelectPicker,
    FormInput,
  },
})
export default class CreativeForm extends ViewModel {
  @Prop()
  public id!: string

  @Prop({ default: '' })
  public advertiser_id!: string

  @Prop({
    default: 'full',
  })
  public view_mode!: string

  @Prop({
    default: '',
  })
  public new_id!: string

  @Prop({
    default: true,
  })
  public show_sidebar!: boolean

  public media_file: VastMediaFile | null = null

  public modal: any = {
    empty: false,
    preview: false,
  }

  public creative: Creative = new Creative()

  public loading: boolean = false

  public asset: any = null

  public busy = true

  public progress = 0

  public creative_types = [
    {
      name: 'Redirect (VAST tag)',
      value: 'vast',
    },
    {
      name: 'Video',
      value: 'video',
    },
  ]

  public get creative_type_options() {
    return Creative.module.type_options
  }

  public get message() {
    if (this.creative.type === 'vast') return 'Saving Creative...'
    if (this.progress < 100) {
      return `Uploading Creative ${this.progress}%...`
    }
    return 'Validating Creative...'
  }

  public onModalHide(e: any) {
    if (e?.trigger == 'inspect') this.creative.inspect()
  }

  public save() {
    this.loading = true
    this.progress = 0
    this.creative
      .save(this.asset, (progressEvent: any) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader('content-length')
            || progressEvent.target.getResponseHeader(
              'x-decompressed-content-length',
            )
        if (totalLength !== null) {
          this.progress = Math.round((progressEvent.loaded * 100) / totalLength)
        }
      })
      .then((response: any) => {
        this.loading = false
        if (response.status == 200) {
          if (this.view_mode == 'sidebar') {
            this.$emit('update:new_id', response.data.result.creative.id)
            this.init()
            this.$emit('update:show_sidebar', false)
          } else {
            this.$router.push({ name: 'Creatives' })
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public onSubmit() {
    this.loading = true

    this.creative
      .test()
      .then((r: any) => {
        if ((r.ads && r.ads.length > 0) || this.creative.type === 'video') {
          this.save()
        } else {
          this.loading = false
          this.modal.empty = true
        }
      })
      .catch(() => {
        WebMessage.error(
          'The provided tag is invalid, please make sure the tag is a valid HTTPS VAST Tag.',
          [
            {
              text: 'Inspect',
              action: () => {
                this.creative.inspect()
              },
            },
          ],
        )
        this.loading = false
      })
  }

  public cancel() {
    if (this.view_mode == 'sidebar') {
      this.$emit('update:show_sidebar', false)
    } else {
      this.$router.push({ name: 'Creatives' })
    }
  }

  @Watch('show_sidebar')
  public onSidebarStatus() {
    this.init()
  }

  public testTag() {
    if (this.creative.vast_url) {
      window.open(
        `https://developers.google.com/interactive-media-ads/docs/sdks/html5/vastinspector?tag=${
          encodeURIComponent(this.creative.vast_url.replace('http:', 'https:'))}`,
        '_blank',
      )
    }
  }

  public previewCreative(creative: Creative) {
    this.creative = creative
    this.media_file = null
    this.creative.preview().then((r: VastMediaFile) => {
      if (r) {
        this.media_file = r
        this.modal.preview = true
      }
    })
  }

  public init() {
    if (this.id) {
      this.busy = true
      Creative.get(this.id).then(o => {
        if (o instanceof Creative) {
          this.creative = o
        }
        this.busy = false
      })
    } else {
      this.creative = new Creative()
      if (this.advertiser_id != '') {
        this.creative.advertiser_id = this.advertiser_id
      }

      this.busy = false
    }
  }

  public mounted() {
    this.init()
  }
}
